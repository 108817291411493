.complex-section-controls {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 0.6rem;
}

.controls {
	display: flex;
	justify-content: space-between;
	width: 100%;
	max-width: 2048px;
}

.complex-section-controls .controls img {
	height: 2.5rem;
	width: 2.5rem;
	padding: 0 0.2rem;
}

.complex-section-controls .controls button {
	display: contents;
	cursor: pointer;
}

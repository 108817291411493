nav {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	width: 100%;
	padding: 0.5rem 1.5rem;
}

nav div {
	display: flex;
	align-items: center;
}

nav img {
	height: 2rem;
	width: 3rem;
}

nav h2 {
	font-weight: 500;
}

nav h2::before {
	content: '-';
	padding: 0 0.6rem;
}

@media (max-width: 700px) {
	nav {
		flex-direction: column;
		text-align: center;
		background-color: rgba(0, 0, 0, 0.03);
	}

	nav h2 {
		font-weight: normal;
		margin-top: 0;
	}

	nav h2::before {
		display: none;
	}
}

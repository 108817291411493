:root {
	--transparency: 0.65;
}

section {
	height: 100vh;
	width: 100vw;
	justify-content: center;
	align-items: center;
	font-size: 2rem;
	font-weight: 600;
	display: none;
}

section.active {
	display: flex;
}

section.red {
	background-color: rgba(255, 0, 0, var(--transparency));
}

section.blue {
	background-color: rgba(0, 0, 255, var(--transparency));
}

section.yellow {
	background-color: rgba(255, 255, 0, var(--transparency));
}

section.green {
	background-color: rgba(0, 128, 0, var(--transparency));
}

section.purple {
	background-color: rgba(128, 0, 128, var(--transparency));
}

section.pink {
	background-color: rgba(255, 192, 203, var(--transparency));
}

section.orange {
	background-color: rgba(255, 136, 0, var(--transparency));
}

section.light-blue {
	background-color: rgba(0, 195, 255, var(--transparency));
}

section.gray {
	background-color: rgba(128, 128, 128, var(--transparency));
}

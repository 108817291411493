footer {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100vw;
	background-color: rgb(102, 100, 100);
	color: rgb(197, 196, 196);
	border-top: 1px solid rgb(197, 196, 196);
	text-align: center;
	padding: 4px;
	font-size: 0.9rem;
}

footer a,
footer a:hover {
	text-decoration: none;
	font-weight: 500;
	color: inherit;
}

@media (max-width: 700px) {
	footer {
		display: none;
	}
}

.ui-caption {
	position: absolute;
	bottom: 1.4rem;
	left: 0;
	width: 100%;
	text-align: center;
	font-size: 1.3rem;
	padding: 0 0.6rem;
}

.ui-caption .commands {
	padding: 0.5rem;
}

.ui-caption .commands img {
	height: 2.5rem;
	width: 2.5rem;
	padding: 0 0.2rem;
}

.ui-caption .commands button {
	display: contents;
	cursor: pointer;
}

@media (max-width: 700px) {
	.ui-caption {
		bottom: 0;
		padding: 0;
	}
	.ui-caption .commands {
		padding-bottom: 0;
	}
}
